import React from 'react'
import TrackVisibility from 'react-on-screen'
import Layout from '../components/Layout'
import CoursesHeader from '../components/Header/CoursesHeader'
import PromotionBannerAnimation from '../components/PromotionBannerAnimation'

import CoursePagination from '../components/CoursePagination/CoursePagination'
import { graphql } from 'gatsby'
import { LazyBlocksFactory } from '../components/LazyBlocksFactory/LazyBlocksFactory'



const OnlineLearning = ({ data }) => {
  const { wpPage } = data
  const renderedBlocks = LazyBlocksFactory(wpPage.lazy_data, wpPage.title)

  return (

    <Layout>
     
      {renderedBlocks.map((v) => v)}    

     
    </Layout>
  )
}

export const query = graphql`
  query OnlineLearningQuery {
    allWpPost {
      edges {
        node {
          id
          categories {
            nodes {
              id
              name
              slug
            }
          }

          title
          slug
        }
      }
    }
    wpPage(title: { eq: "Online Learning" }) {
      id
      lazy_data
      title
    }
  }
`

export default OnlineLearning
